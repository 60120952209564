@font-face {
	font-family: 'Arial';
	src: url('../fonts/arial.ttf') format('truetype');
	font-weight: normal;
}
@font-face {
	font-family: 'Arial';
	src: url('../fonts/arial-light.otf') format('opentype');
	font-weight: 300;
}
@font-face {
	font-family: 'Arial';
	src: url('../fonts/arial-bold.otf') format('opentype');
	font-weight: bold;
}


@keyframes line {
	0% { 
		width: 0%; 
	}
	100% { 
		width: 100%; 
	}
}

// Variables
$color-green:#00a6aa;

html {
	font-size: 100%;
	@media(max-width: 640px) {
		font-size: 87.5%;
	}
}

body {
	font-size: 100%;
	line-height: 1.5;
	font-family: 'Arial';
	font-weight: normal;
	background: #f5f5f5;
	margin: 0;
	padding: 0;
}


.color-green {
	color: $color-green;
}

* {
	box-sizing: border-box;
}

strong {
	font-weight: bold;
}

p {
	font-weight: normal;
}

a {
	text-decoration: none;
}

ul {
	padding-left: 1.5rem;
	li {
		font-size: 1.25rem;
		margin-bottom: 0.25rem;
		@media(max-width: 480px) {
			font-size: 1rem;
		}
	}
}

h1 {
	font-size: 4rem;
	line-height: 4.5rem;
}

h2 {
	font-size: 3rem;
	line-height: 3.5rem;
}

h1,h2 {
	color: $color-green;
	margin-bottom: 2rem;
	font-weight: 300;
}

p {
	font-size: 1.25rem;
	line-height: 1.7;
	margin-top: 0;
	margin-bottom: 1.5rem;
	@media(max-width: 480px) {
		font-size: 1rem;
		line-height: 1.5;
	}
}

p, ul, li, a {
	color: #51667b;
}

a:focus {
	outline: 0;
}

.container {
	width: 75rem;
    max-width: 90%;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

header {
	padding: 2rem 0;
	.container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	p {
		color: $color-green;
		flex-shrink: 0;
		margin: 0;
		font-weight: bold;
	}
	.logo {
		width: 10rem;
		height: auto;
		flex-shrink: 0;
		margin-top: -15px;
	}
	.line {
		background: $color-green;
		height: 2px;
		width: calc(100% - 3rem);
		flex-grow: 1;
		margin: 0 1.5rem;
		animation: line 4s ease-in-out infinite;
		transition: all 4s ease;
	}
}

.banner {
	position: relative;
	width: 100%;
	height: 70vh;
	min-height: 48rem;
	margin-bottom: 5rem;
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.5);
		z-index: 5;
	}
	video {
		width: 100%;
		height: 100%;
		object-fit: cover;
		position: absolute;
		top: 0;
		left: 0;
	}
	.banner-content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 10;
		text-align: center;
		width: 70rem;
		z-index: 10;
		@media(max-width: 1100px) {
			max-width: 90%;
		}
		.play-icon {
			border: solid 2px #fff;
			width: 5rem;
			height: 5rem;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 100%;
			overflow: hidden;
			margin: auto;
			svg {
				margin-left: 6px;
			}
		}
		.title {
			color: #fff;
			font-size: 4rem;
			line-height: 4.5rem;
			margin: 1.5rem 0;
			@media(max-width: 1100px) {
				font-size: 3rem;
				line-height: 3.5rem;
			}
			@media(max-width: 360px) {
				font-size: 2rem;
				line-height: 2.5rem;
			}
		}
		.subtitle {
			color: $color-green;
			font-size: 2.5rem;
			margin: 0;
			font-weight: 300;
			@media(max-width: 1100px) {
				font-size: 1.5rem;
				line-height: 2rem;
			}
		}
	}
}

.fancybox-video {
	display: none;
}

.main-content {
	.introduction-content {
		text-align: center;
		margin-bottom: 5rem;
		.title {
			@media(max-width: 640px) {
				font-size: 2.5rem;
			}
		}
		p {
			font-size: 1.3125rem;
			max-width: 58rem;
			margin-left: auto;
			margin-right: auto;
			@media(max-width: 480px) {
				font-size: 1rem;
			}
		}
	}
}

.image-content {
	display: flex;
	margin-bottom: 5rem;
	@media(max-width: 1024px) {
		flex-direction: column-reverse;
	}
	.image {
		width: 50%;
		@media(max-width: 1024px) {
			width: 100%;
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.content {
		width: 50%;
		padding: 3rem;
		background: #ccc;
		@media(max-width: 1024px) {
			width: 100%;
		}
	}
}


.bottom-content {
	.video-link {
		margin: 3rem 0 1.5rem 0;
		color: #fff;
		background: red;
		text-align: center;
		padding: 1.5rem;
		display: block;
		font-size: 2.5rem;
		font-weight: normal;
		@media(max-width: 480px) {
			font-size: 1.5rem;
		}
	}
	img {
		object-fit: cover;
		width: 100%;
		height: auto;
	}
	margin-bottom: 3rem;
}

footer {
	padding: 1rem;
	background: $color-green;
	.container {
		display: flex;
		justify-content: space-between;
		@media(max-width: 480px) {
			flex-direction: column;
			text-align: center;
		}
	}
	p {
		color: #fff;
		font-size: 1rem;
		margin: 0;
	}
}

.fancybox-video {
	video {
		margin-left: auto;
		margin-right: auto;
		display: block;
		padding: 20px;
		background: #fff;
		margin-top: 100px;
		object-fit: cover;
		width: 77rem;
		max-width: 90%;
		height: auto;
	}
	.fancybox-close-small {
		width: 4rem;
		height: 4rem;
		background: #fff;
		color: #000;
	}
}

.fancybox-slide {
	padding: 0;
	background: rgba(255,255,255,0.5);
}
